import React from 'react'
import { usePickEpiContent, useGtmWizardTracker } from '@local/Utils/Hooks'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import AnstallningTIAForm from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/AnstallningTIAForm'
import Spinner from '@local/Components/Spinner'

const AnstallningTIA = () => {
  const { anstallning } = usePickEpiContent<ITjanstemanIAnstallningContent>()
  useGtmWizardTracker('TIA-Ansökan')

  const { data, isLoading } = useGetAnsokanQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  if (isLoading) {
    return <Spinner centered />
  }

  return <AnstallningTIAForm content={anstallning} ansokan={data} />
}

export default AnstallningTIA
