import React, { useCallback } from 'react'
import { anstallningTIAValidationSchema } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/AnstallningTIAForm.schema'
import { mapAnsokanToAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Helpers'
import {
  IAnstallningTIAContent,
  IAnstallningFormProps,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types/content'
import { useWizard, WizardStep } from '@trr/wizard-library'
import {
  FormikTouched,
  setNestedObjectValues,
  useFormikContext,
  withFormik,
} from 'formik'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/form.helpers'
import Gutter from '@local/Components/Gutter'
import { isEmpty } from 'ramda'
import {
  IAnstallningTIAFormValues,
  IAnstallningFormValuesApiModel,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types'
import { usePickEpiContent, useWizardNextStep } from '@local/Utils/Hooks'
import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import { Befattningar } from '@local/Components/Befattningar/Befattningar'
import { Bilagor } from '@local/Components/Bilagor/Bilagor'
import useFormikWatch from '@local/Utils/Hooks/useFormikWatch'
import { anstallningTIAMiddleware } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/anstallningTIAMiddleware'
import { useAppDispatch } from '@local/Store/configureStore'
import { setShouldGoToNextStep } from '@local/Utils/Hooks/formikWatchSlice'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { AnstallningFormTypes } from '@local/Views/SkapaAnsokan/Types'
import VD from '@local/Views/SkapaAnsokan/Components/VD'
import Manadslon from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Components/Manadslon'
import DateInput from '@local/Components/DateInput'
import { useGetAnsokanState } from '@local/Services/API/hamtaAnsokanApi'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { Grid } from '@mui/material'
import { yesterday } from '@local/Utils/Helpers/Datetime.helpers'

const AnstallningTIAForm = () => {
  const dispatch = useAppDispatch()
  const { stepCount, activeStep } = useWizard()
  const [patchAnsokan] = usePatchAnsokanMutation({
    fixedCacheKey: 'patchAnsokan',
  })
  const {
    data: {
      foretag: { namn: foretagNamn },
    },
  } = useGetAnsokanState()

  const formikContext = useFormikContext<IAnstallningTIAFormValues>()
  const { errors, touched, values, setFieldValue, setTouched, validateForm } =
    formikContext
  const {
    anstallning: {
      stegGuide,
      befattning,
      anstallningsdatum,
      bifogaArbetsgivarintyg,
      bifogaFiler,
      tooltipBefattning,
      tooltipAnstallningsdatum,
      tooltipArbetsgivarintyg,
      laddaUppBilaga,
    },
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  const patchAnsokanCallback = useCallback(
    async (values: Partial<IAnstallningFormValuesApiModel>) => {
      await patchAnsokan({ values })
    },
    [patchAnsokan]
  )

  useFormikWatch(anstallningTIAMiddleware, patchAnsokanCallback)
  useWizardNextStep()

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          setTouched(
            setNestedObjectValues<FormikTouched<IAnstallningTIAFormValues>>(
              validationErrors,
              true
            )
          ).catch((err) => console.log(err))
        } else {
          dispatch(setShouldGoToNextStep(true))
        }
      })
      .catch((e) => console.error(e))
  }

  return (
    <Grid container>
      <Grid item lg={8} sx={{ width: 1 }}>
        <WizardStep
          progressBar={{ max: stepCount - 1, current: activeStep + 1 }}
          stepInfo={{
            currentStepLabel: stegGuide.nuvarandeSteg,
            nextStepLabel: stegGuide.nastaSteg,
          }}
          nextButton={{ onClick: handleNext }}
          prevButton={{}}
        >
          <Befattningar
            formikContext={formikContext}
            content={befattning}
            tooltipContent={tooltipBefattning}
          />

          <Gutter xs={32} />

          <VD />

          <Gutter xs={32} />

          <DateInput
            formikFieldName={AnstallningFormTypes.Anstallningsdatum}
            formikContext={formikContext}
            label={anstallningsdatum.textFaltTitel}
            tooltipContent={tooltipAnstallningsdatum}
            maxDate={yesterday}
            title={replaceEpiVariables(anstallningsdatum.titel, {
              foretag: foretagNamn,
            })}
          />

          <Gutter xs={32} />

          <Manadslon />

          <Gutter xs={32} />

          <Bilagor
            content={{
              ...laddaUppBilaga,
              description: bifogaFiler.mainBody,
              heading: bifogaArbetsgivarintyg.heading,
              uploadButtonText: bifogaArbetsgivarintyg.laddaUppKnapp,
              tooltip: tooltipArbetsgivarintyg.mainBody,
            }}
            formikFieldName={AnstallningFormTypes.AnstallningBilagor}
            files={values.anstallningBilagor}
            setFieldValue={setFieldValue}
            errorMessage={generateErrorMessage({
              touched: touched?.anstallningBilagor !== undefined,
              errorMsg: errors?.anstallningBilagor as string,
            })}
          />
        </WizardStep>
      </Grid>
    </Grid>
  )
}

const AnstallningFormik = withFormik<
  IAnstallningFormProps,
  IAnstallningTIAFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ ansokan }) => mapAnsokanToAnstallningFormValues(ansokan),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content }: { content: IAnstallningTIAContent }) =>
    anstallningTIAValidationSchema(content),
  displayName: 'AnstallningTIAForm',
})(AnstallningTIAForm)

export default AnstallningFormik
