import * as Yup from 'yup'
import { IUppsagningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Types'
import { IUppsagningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Types/content'
import { isAfter, isBefore, subDays } from 'date-fns'
import { getDate } from '@local/Utils/Helpers/formatDate'
import { today } from '@local/Utils/Helpers/Datetime.helpers'
import { email } from '@local/Utils/Helpers/regexes'

export const uppsagningValidationSchema = (
  content: IUppsagningContent,
  sistaAnstallningsdatumMinDate: Date
): Yup.AnySchema<IUppsagningFormValues> =>
  Yup.object().shape({
    sistaAnstallningsdatum: Yup.date()
      .typeError(content.sistaAnstallningsdatum.varning)
      .required(content.sistaAnstallningsdatum.varning)
      .test(
        'validateMinDate',
        content.sistaAnstallningsdatum.varningMinDate,
        (date) => isAfter(date, subDays(sistaAnstallningsdatumMinDate, 1))
      )
      .test(
        'validateMaxDate',
        content.sistaAnstallningsdatum.varningMaxDate,
        (date) => isBefore(new Date(getDate(date)), new Date(getDate(today)))
      ),
    foretagKontaktpersonEpostadress: Yup.string()
      .nullable()
      .required(content.kontaktPersoner.varningMailadress)
      .matches(email, {
        message: content.kontaktPersoner.varningMailadressFelaktig,
      }),
    foretagKontaktpersonNamn: Yup.string()
      .nullable()
      .required(content.kontaktPersoner.varningNamn),
  })

export const initialUppsagningValues: IUppsagningFormValues = {
  sistaAnstallningsdatum: null,
  foretagKontaktpersonEpostadress: null,
  foretagKontaktpersonNamn: null,
}
