import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { IArbetsplatsFormValues } from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Types'
import { useFormikContext } from 'formik'
import { isNil } from 'ramda'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { SkapaAnsokanSharedContent } from '@local/Types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useGetAnsokanState } from '@local/Services/API/hamtaAnsokanApi'
import { RadioChoice } from '@local/Types/form.types'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import Gutter from '@local/Components/Gutter'

const NuvarandeArbetsplats = (): JSX.Element => {
  const {
    data: {
      foretag: { namn: foretagNamn },
    },
  } = useGetAnsokanState()

  const { handleChange, handleBlur, errors, touched, values } =
    useFormikContext<IArbetsplatsFormValues>()

  const {
    arbetsplats: { nuvarandeArbetsplats },
  } = usePickEpiContent<SkapaAnsokanSharedContent>()

  return (
    <FormControl
      error={
        touched.nuvarandeArbetsplats && !isNil(errors.nuvarandeArbetsplats)
      }
      variant="standard"
      fullWidth
    >
      <Typography variant="h6">
        {replaceEpiVariables(nuvarandeArbetsplats.titel, {
          foretag: foretagNamn,
        })}
      </Typography>

      <Gutter xs={16} />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.nuvarandeArbetsplats}
      >
        <FormControlLabel
          value={RadioChoice.Yes}
          control={<Radio color="primary" />}
          label={nuvarandeArbetsplats.ja}
          name="nuvarandeArbetsplats"
          id="nuvarandeArbetsplats.ja"
        />
        <FormControlLabel
          value={RadioChoice.No}
          control={<Radio color="primary" />}
          label={nuvarandeArbetsplats.nej}
          name="nuvarandeArbetsplats"
          id="nuvarandeArbetsplats.nej"
        />
      </RadioGroup>
      {touched.nuvarandeArbetsplats && !isNil(errors.nuvarandeArbetsplats) && (
        <FormErrorMessage>{errors.nuvarandeArbetsplats}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default NuvarandeArbetsplats
