import { IGranskaAnsokanContent } from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan/Types/content'
import * as Yup from 'yup'

export interface IGranskaAnsokanFormValues {
  forsakratUppgifter: boolean
}

export const granskaAnsokanValidationSchema = (
  content: IGranskaAnsokanContent
): Yup.AnySchema<IGranskaAnsokanFormValues> =>
  Yup.object().shape({
    forsakratUppgifter: Yup.boolean().oneOf(
      [true],
      content.forsakraUppgifter.varning
    ),
  })

export const initialGranskaAnsokanValues: IGranskaAnsokanFormValues = {
  forsakratUppgifter: false,
}
