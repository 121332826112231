import * as Yup from 'yup'
import { countDecimals } from '@local/Utils/Helpers/Forms/form.helpers'
import { IAnstallningTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Types'
import { IAnstallningTbContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Types/content'
import { RadioChoice } from '@local/Types/form.types'
import { getDate } from '@local/Utils/Helpers/formatDate'
import { isAfter, isBefore, subDays } from 'date-fns'
import { startDate, today } from '@local/Utils/Helpers/Datetime.helpers'

export const anstallningTBValidationSchema = (
  content: IAnstallningTbContent
): Yup.AnySchema<IAnstallningTBFormValues> =>
  Yup.object().shape({
    anstallningsgrad: Yup.number()
      .nullable()
      .required(content.anstallningsgrad.varning)
      .integer(content.anstallningsgrad.varningFelaktigt)
      .min(5, content.anstallningsgrad.varningNegativt)
      .max(100, content.anstallningsgrad.varningHogtVarde),
    arbetstimmarPerVecka: Yup.number()
      .nullable()
      .required(content.arbetstimmarPerVecka.varning)
      .positive(content.arbetstimmarPerVecka.varningNegativt)
      .max(80, content.arbetstimmarPerVecka.varningHogtVarde)
      .test(
        'len',
        content.arbetstimmarPerVecka.varningDecimaler,
        (value: number) => countDecimals(value) <= 2
      ),
    befattning: Yup.mixed().nullable().required(content.befattning.varning),
    isVdEllerUndantagsgrupp: Yup.mixed()
      .oneOf(Object.values(RadioChoice))
      .required(content.vd.varning),
    anstallningsdatum: Yup.date()
      .typeError(content.anstallningsdatum.varning)
      .required(content.anstallningsdatum.varning)
      .test(
        'validateMinDate',
        content.anstallningsdatum.varningMinDate,
        (date) => isAfter(date, subDays(startDate, 1))
      )
      .test(
        'validateMaxDate',
        content.anstallningsdatum.varningMaxDate,
        (date) => isBefore(new Date(getDate(date)), new Date(getDate(today)))
      ),
    anstallningBilagor: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          name: Yup.string().nullable(),
        })
      )
      .min(1, content.bifogaArbetsgivarintyg.varning),
  })

export const initialAnstallningTBValues: IAnstallningTBFormValues = {
  befattning: null,
  isVdEllerUndantagsgrupp: undefined,
  anstallningsdatum: null,
  arbetstimmarPerVecka: null,
  anstallningBilagor: [],
  anstallningsgrad: null,
}
