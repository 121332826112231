import React from 'react'
import { isNil } from 'ramda'
import { useFormikContext } from 'formik'
import {
  ArbetslivserfarenhetTIAFormTypes,
  IArbetslivserfarenhetTIAFormValues,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Types'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import HTMLMapper from '@local/Components/HTMLMapper'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import Tooltip from '@local/Components/Tooltip'
import Gutter from '@local/Components/Gutter'

const Arbetstimmar = (): JSX.Element => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<IArbetslivserfarenhetTIAFormValues>()

  const {
    arbetslivserfarenhet: { arbetstimmar, tooltipArbetstimmar },
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  const isError =
    touched.arbetstimmarPerVecka && !isNil(errors.arbetstimmarPerVecka)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Tooltip
        heading={arbetstimmar.heading}
        tooltipContent={<HTMLMapper body={tooltipArbetstimmar.mainBody} />}
      />

      <Typography variant="body1">{arbetstimmar.preamble}</Typography>

      <Gutter xs={16} />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.arbetstimmarPerVecka}
      >
        <FormControlLabel
          value={16}
          control={<Radio color="primary" />}
          label={arbetstimmar.ja}
          name={ArbetslivserfarenhetTIAFormTypes.ArbetstimmarPerVecka}
          id={`${ArbetslivserfarenhetTIAFormTypes.ArbetstimmarPerVecka}.ja`}
        />
        <FormControlLabel
          value={15}
          control={<Radio color="primary" />}
          label={arbetstimmar.nej}
          name={ArbetslivserfarenhetTIAFormTypes.ArbetstimmarPerVecka}
          id={`${ArbetslivserfarenhetTIAFormTypes.ArbetstimmarPerVecka}.nej`}
        />
      </RadioGroup>
      {isError && (
        <FormErrorMessage>{errors.arbetstimmarPerVecka}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default Arbetstimmar
