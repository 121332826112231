import Gutter from '@local/Components/Gutter'
import { WizardStep } from '@trr/wizard-library'
import React from 'react'
import { useGtmWizardTracker, usePickEpiContent } from '@local/Utils/Hooks'
import HTMLMapper from '@local/Components/HTMLMapper'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import useOnUnmount from '@local/Utils/Hooks/useOnUnmount'
import { baseApi } from '@local/Services/API/baseApi'
import { useAppDispatch } from '@local/Store/configureStore'
import EpiImage from '@local/Components/EpiImage/EpiImage'
import { SkapaAnsokanSharedContent } from '@local/Types'
import { Button } from '@mui/material'
import { useUser } from '@trr/app-shell-data'
import { useIsTiaAnsokan } from '@local/Utils/Hooks/SkapaAnsokan'

const SlutfordAnsokan = () => {
  const {
    slutfordAnsokan: {
      textContent,
      dinaAnsokningarLink: { heading, url },
      bild,
    },
  } = usePickEpiContent<SkapaAnsokanSharedContent>()

  const { firstName } = useUser()

  const isTia = useIsTiaAnsokan()
  useGtmWizardTracker(isTia ? 'TIA-Ansökan' : 'TB-Ansökan')

  const dispatch = useAppDispatch()
  useOnUnmount(() => {
    dispatch(baseApi.util.invalidateTags(['ansokan', 'ansokanDraft']))
  })

  return (
    <WizardStep>
      <EpiImage url={bild.url} />

      <Gutter xs={40} />

      <HTMLMapper
        body={replaceEpiVariables(textContent.mainBody, {
          fornamn: firstName,
        })}
      />

      <Gutter xs={48} />

      <Button variant="contained" href={url}>
        {heading}
      </Button>
    </WizardStep>
  )
}

export default SlutfordAnsokan
