import React from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useFormikContext } from 'formik'
import HTMLMapper from '@local/Components/HTMLMapper'
import {
  ILonOchErsattningFormValues,
  LonOchErsattningFormTypes,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Types'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import Tooltip from '@local/Components/Tooltip'
import NumberInput from '@local/Components/NumberInput'

const Manadslon = (): JSX.Element => {
  const {
    lonOchErsattning: { manadslon, tooltipManadslon },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const formikContext = useFormikContext<ILonOchErsattningFormValues>()

  return (
    <>
      <Tooltip
        heading={manadslon.titel}
        tooltipContent={<HTMLMapper body={tooltipManadslon.mainBody} />}
      />

      <NumberInput
        formikContext={formikContext}
        label={manadslon.textFaltTitel}
        name={LonOchErsattningFormTypes.Manadslon}
      />
    </>
  )
}

export default Manadslon
