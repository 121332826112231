import Gutter from '@local/Components/Gutter'
import { ISelectOptionFormikWrapper } from '@local/Types/form.types'
import SammanfattningUndersektion from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan/Components/SammanfattningUndersektion'
import { Typography } from '@mui/material'
import React from 'react'

export interface IUndersektion {
  title: string
  body: string | number | JSX.Element | ISelectOptionFormikWrapper
}

export interface ISammanfattningSektionProps {
  title: string
  undersektioner: IUndersektion[]
}

export const SammanfattningSektion = ({
  title,
  undersektioner,
}: ISammanfattningSektionProps) => (
  <>
    <Typography variant="h5">{title}</Typography>

    <Gutter xs={16} />

    {undersektioner.map((sektion) => (
      <SammanfattningUndersektion sektion={sektion} key={sektion.title} />
    ))}
  </>
)
