import React from 'react'
import { Provider } from 'react-redux'
import { store } from '@local/Store/configureStore'
import { AppShellRouter, KeyRoute } from '@local/Router'
import OnboardingWrapper from '@local/Views/OnboardingWrapper'
import { Routes } from '@local/Router/routes'
import OgiltigtOrgnummer from '@local/Views/OgiltigtOrgnummer'
import TidsbegransadAnstallning from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning'
import TjanstemanIAnstallning from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning'
import { createTheme, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { svSE } from '@mui/x-date-pickers/locales'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { sv } from 'date-fns/locale'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { GTMTracker } from '@trr/gtm-tracking'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale, svSE)

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <GTMTracker mfName="tjanstepersonansokan">
          <Provider store={store}>
            <AppShellDataProvider value={appShellData}>
              <AppShellRouter currentKey={appShellData.currentKey}>
                <KeyRoute urlKey={Routes.Start}>
                  <OnboardingWrapper />
                </KeyRoute>

                <KeyRoute urlKey={Routes.OgiltigtOrgnummer}>
                  <OgiltigtOrgnummer />
                </KeyRoute>

                <KeyRoute urlKey={Routes.TiaAnsokan}>
                  <TjanstemanIAnstallning />
                </KeyRoute>

                <KeyRoute urlKey={Routes.TbAnsokan}>
                  <TidsbegransadAnstallning />
                </KeyRoute>
              </AppShellRouter>
            </AppShellDataProvider>
          </Provider>
        </GTMTracker>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
