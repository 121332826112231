import { useWizard } from '@trr/wizard-library'
import { useTrackVirtualView } from '@trr/gtm-tracking'
import { useEffect } from 'react'
import { useIsFeatureEnabled } from '@trr/app-shell-data'

const useGtmWizardTracker = (wizardName: string) => {
  const stepTrigger = useTrackVirtualView(wizardName)
  const { activeStep } = useWizard()
  const shouldTrack = useIsFeatureEnabled('gtm.enabled.tjanstepersonansokan')

  useEffect(() => {
    if (shouldTrack) {
      stepTrigger(`Step: ${activeStep}`)
    }
  }, [stepTrigger, activeStep, shouldTrack])
}

export default useGtmWizardTracker
