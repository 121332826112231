import React from 'react'
import {
  useIsTIA,
  useIsTIOTB,
  useIsTIOTV,
} from '@local/Utils/Hooks/OnboardingForm'
import { usePickEpiContent } from '@local/Utils/Hooks'
import HTMLMapper from '@local/Components/HTMLMapper'
import { ITjanstepersonAnsokanContent } from '@local/Types'

const RoleBasedContent = (): JSX.Element => {
  const {
    onboarding: { onboardingTia, onboardingTb, onboardingTv },
  } = usePickEpiContent<ITjanstepersonAnsokanContent>()

  const isTIA = useIsTIA()
  const isTIOTV = useIsTIOTV()
  const isTIOTB = useIsTIOTB()

  let content = null

  if (isTIA) {
    content = onboardingTia
  }

  if (isTIOTB) {
    content = onboardingTb
  }

  if (isTIOTV) {
    content = onboardingTv
  }

  return content && <HTMLMapper body={content.mainBody} />
}

export default RoleBasedContent
