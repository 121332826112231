import React, { useState } from 'react'
import Gutter from '@local/Components/Gutter'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { usePickEpiContent, useToggle } from '@local/Utils/Hooks'
import {
  PaborjadAnsokanProps,
  PaborjadAnsokanVal,
} from '@local/Views/OnboardingWrapper/PaborjadAnsokan/Types'
import { ITjanstepersonAnsokanContent } from '@local/Types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import CollapsibleAlert from '@local/Components/CollapsibleAlert'
import { useUser } from '@trr/app-shell-data'

const PaborjadAnsokan = ({
  ansokanValValue,
  onContinue,
  setAnsokanVal,
}: PaborjadAnsokanProps) => {
  const [alertIsOpen, setAlertIsOpen] = useState(true)
  const { isToggled: isModalOpen, handleToggle } = useToggle()
  const handleAnsokanVal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnsokanVal(e.target.value as PaborjadAnsokanVal)
  }
  const { firstName } = useUser()

  const {
    paborjadAnsokan: {
      textinnehall,
      paborjadAnsokanVal,
      nastaKnapp,
      raderaAnsokanModal,
    },
  } = usePickEpiContent<ITjanstepersonAnsokanContent>()

  const handleContinue = () => {
    if (ansokanValValue === PaborjadAnsokanVal.Fortsatt) {
      void onContinue()
    } else {
      handleToggle()
    }
  }

  const handleConfirm = () => {
    void onContinue()
  }

  return (
    <>
      <Typography variant="h1">
        {replaceEpiVariables(textinnehall.titel, {
          fornamn: firstName,
        })}
      </Typography>

      <Gutter xs={24} />

      <Typography variant="body1">{textinnehall.ingress}</Typography>

      <Gutter xs={48} />

      <CollapsibleAlert
        open={alertIsOpen}
        setIsOpen={setAlertIsOpen}
        severity="warning"
        message={textinnehall.infoHantera}
      />

      <Gutter xs={24} />

      <FormControl variant="standard" fullWidth>
        <Typography variant="h6">{paborjadAnsokanVal.titel}</Typography>

        <Gutter xs={16} />

        <RadioGroup
          onChange={handleAnsokanVal}
          defaultValue={PaborjadAnsokanVal.Fortsatt}
        >
          <FormControlLabel
            value={PaborjadAnsokanVal.Fortsatt}
            control={<Radio color="primary" />}
            label={paborjadAnsokanVal.fortsatt}
            name="paborjadAnsokan"
            id="paborjadAnsokan.fortsätt"
          />
          <FormControlLabel
            value={PaborjadAnsokanVal.Radera}
            control={<Radio color="primary" />}
            label={paborjadAnsokanVal.radera}
            name="paborjadAnsokan"
            id="paborjadAnsokan.radera"
          />
        </RadioGroup>
      </FormControl>

      <Gutter xs={32} />

      <Button color="primary" onClick={handleContinue}>
        {nastaKnapp.text}
      </Button>

      <Dialog onClose={handleToggle} open={isModalOpen}>
        <DialogTitle>{raderaAnsokanModal.titel}</DialogTitle>
        <DialogActions>
          <Button variant="text" onClick={handleToggle}>
            {raderaAnsokanModal.avbrytKnapp}
          </Button>
          <Button variant="text" onClick={handleConfirm}>
            {raderaAnsokanModal.fortsattKnapp}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PaborjadAnsokan
