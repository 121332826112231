import React from 'react'
import { isNil } from 'ramda'
import { useFormikContext } from 'formik'
import Gutter from '@local/Components/Gutter'
import HTMLMapper from '@local/Components/HTMLMapper'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { RadioChoice } from '@local/Types/form.types'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import {
  ArbetslivserfarenhetSharedFormValues,
  EtableringsvillkorFormTypes,
} from '@local/Views/SkapaAnsokan/Components/Etableringsvillkor/Types'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import Tooltip from '@local/Components/Tooltip'
import FormErrorMessage from '@local/Components/FormErrorMessage'

const Etableringsvillkor = () => {
  const { errors, touched, values, handleBlur, handleChange } =
    useFormikContext<ArbetslivserfarenhetSharedFormValues>()

  const {
    arbetslivserfarenhet: {
      etableringsvillkor: { heading, ja: labelJa, nej: labelNej },
      etableringsvillkorbeskrivning: { mainBody: description },
      etableringsvillkortooltip: { mainBody: tooltip },
    },
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  const isError =
    touched.hasIntygatEtableringsvillkor &&
    !isNil(errors.hasIntygatEtableringsvillkor)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Tooltip
        heading={heading}
        tooltipContent={<HTMLMapper body={tooltip} />}
      />

      <HTMLMapper body={description} />

      <Gutter xs={24} />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.hasIntygatEtableringsvillkor}
      >
        <FormControlLabel
          value={RadioChoice.Yes}
          control={<Radio color="primary" />}
          label={labelJa}
          name={EtableringsvillkorFormTypes.HasIntygatEtableringsvillkor}
          id={`${EtableringsvillkorFormTypes.HasIntygatEtableringsvillkor}-${labelJa}`}
        />
        <FormControlLabel
          value={RadioChoice.No}
          control={<Radio color="primary" />}
          label={labelNej}
          name={EtableringsvillkorFormTypes.HasIntygatEtableringsvillkor}
          id={`${EtableringsvillkorFormTypes.HasIntygatEtableringsvillkor}-${labelNej}`}
        />
      </RadioGroup>

      {isError && (
        <FormErrorMessage>
          {errors.hasIntygatEtableringsvillkor}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default Etableringsvillkor
