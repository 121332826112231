import React from 'react'
import { withFormik } from 'formik'
import {
  IOnboardingFormValues,
  onboardingValidationSchema,
} from '@local/Views/OnboardingWrapper/Onboarding/OnboardingForm/OnboardingForm.schema'
import Gutter from '@local/Components/Gutter'
import { useGetAnsokanDraftState } from '@local/Services/API/hamtaAnsokanApi'
import Anstallningssituation from '@local/Views/OnboardingWrapper/Onboarding/Components/Anstallningssituation/Anstallningssituation'
import AnstallningssituationTIO from '@local/Views/OnboardingWrapper/Onboarding/Components/Anstallningssituation/AnstallningssituationTIO/AnstallningssituationTIO'
import {
  useIsTIA,
  useIsTIOTB,
  useIsTIO,
} from '@local/Utils/Hooks/OnboardingForm'
import {
  IOnboardingContent,
  IOnboardingFormProps,
} from '@local/Views/OnboardingWrapper/Onboarding/Types/Onboarding.types'
import { mapOnboardingFormValues } from '@local/Views/OnboardingWrapper/Onboarding/Helpers'
import Preamble from '@local/Views/OnboardingWrapper/Onboarding/Components/Preamble'
import Villkor from '@local/Views/OnboardingWrapper/Onboarding/Components/Villkor'
import OnboardingFormSubmit from '@local/Views/OnboardingWrapper/Onboarding/Components/OnboardingFormSubmit'
import RoleBasedContent from '@local/Views/OnboardingWrapper/Onboarding/Components/RoleBasedContent'
import { Grid } from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'

const OnboardingForm = () => {
  const { sub } = useAuthentication()
  const { data: ansokanDraft } = useGetAnsokanDraftState(sub)
  const isTIA = useIsTIA()
  const isTIOTB = useIsTIOTB()
  const isTIO = useIsTIO()

  return (
    <Grid container>
      <Grid item lg={8}>
        <Preamble />

        <Gutter xs={64} />

        {ansokanDraft === null && (
          <>
            <Anstallningssituation />

            <Gutter xs={32} />

            {isTIO && <AnstallningssituationTIO />}
          </>
        )}

        <RoleBasedContent />

        {(isTIA || isTIOTB) && (
          <>
            <Villkor />

            <Gutter xs={32} />

            <OnboardingFormSubmit />
          </>
        )}
      </Grid>
    </Grid>
  )
}

const OnboardingFormik = withFormik<
  IOnboardingFormProps,
  IOnboardingFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ ansokanDraft }) => mapOnboardingFormValues(ansokanDraft),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content }: { content: IOnboardingContent }) =>
    onboardingValidationSchema(content),
  displayName: 'OnboardingForm',
})(OnboardingForm)

export default OnboardingFormik
