import React from 'react'
import { styled } from '@mui/material/styles'

const StyledDivider = styled('hr')(({ theme }) => ({
  margin: 0,
  border: 0,
  borderTop: `1px solid ${theme.palette.neutral?.divider}`,
}))

export const Divider = () => <StyledDivider />
