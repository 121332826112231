import { IArbetslivserfarenhetTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/Types'
import { IArbetslivserfarenhetTBContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/Types/content'
import * as Yup from 'yup'

export const arbetslivserfarenhetTBValidationSchema = (
  content: IArbetslivserfarenhetTBContent
): Yup.AnySchema<IArbetslivserfarenhetTBFormValues> =>
  Yup.object().shape({
    hasIntygatEtableringsvillkor: Yup.mixed().required(
      content.etableringsvillkor.varning
    ),
  })

export const initialArbetslivserfarenhetTBValues: IArbetslivserfarenhetTBFormValues =
  {
    hasIntygatEtableringsvillkor: null,
  }
