import React, { useCallback } from 'react'
import { mapAnsokanToAnstallningTbFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Helpers'
import {
  IAnstallningTbContent,
  IAnstallningTbFormProps,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Types/content'
import { anstallningTBValidationSchema } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/AnstallningTBForm.schema'
import { isEmpty } from 'ramda'
import {
  useFormikContext,
  setNestedObjectValues,
  FormikTouched,
  withFormik,
} from 'formik'
import { useWizard, WizardStep } from '@trr/wizard-library'
import { Befattningar } from '@local/Components/Befattningar/Befattningar'
import { Bilagor } from '@local/Components/Bilagor/Bilagor'
import Gutter from '@local/Components/Gutter'
import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/form.helpers'
import { usePickEpiContent, useWizardNextStep } from '@local/Utils/Hooks'
import {
  IAnstallningTBFormValues,
  IAnstallningTBFormValuesApiModel,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Types'
import Anstallningsgrad from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Components/Anstallningsgrad'
import ArbetstimmarPerVecka from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Components/ArbetstimmarPerVecka'
import useFormikWatch from '@local/Utils/Hooks/useFormikWatch'
import { anstallningTBMiddleware } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/anstallningTBMiddleware'
import { useAppDispatch } from '@local/Store/configureStore'
import { setShouldGoToNextStep } from '@local/Utils/Hooks/formikWatchSlice'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { AnstallningFormTypes } from '@local/Views/SkapaAnsokan/Types'
import VD from '@local/Views/SkapaAnsokan/Components/VD'
import DateInput from '@local/Components/DateInput'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { useGetAnsokanState } from '@local/Services/API/hamtaAnsokanApi'
import { Grid } from '@mui/material'
import { yesterday } from '@local/Utils/Helpers/Datetime.helpers'

const AnstallningTBForm = () => {
  const dispatch = useAppDispatch()
  const { stepCount, activeStep } = useWizard()
  const [patchAnsokan] = usePatchAnsokanMutation({
    fixedCacheKey: 'patchAnsokan',
  })

  const formikContext = useFormikContext<IAnstallningTBFormValues>()
  const { errors, touched, values, setFieldValue, setTouched, validateForm } =
    formikContext

  const {
    anstallningTb: {
      stegGuide,
      befattning,
      anstallningsdatum,
      bifogaArbetsgivarintyg,
      tooltipBefattning,
      laddaUppBilaga,
    },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const {
    data: {
      foretag: { namn: foretagNamn },
    },
  } = useGetAnsokanState()

  const patchAnsokanCallback = useCallback(
    async (values: Partial<IAnstallningTBFormValuesApiModel>) => {
      await patchAnsokan({ values })
    },
    [patchAnsokan]
  )

  useFormikWatch(anstallningTBMiddleware, patchAnsokanCallback)
  useWizardNextStep()

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          setTouched(
            setNestedObjectValues<FormikTouched<IAnstallningTBFormValues>>(
              validationErrors,
              true
            )
          ).catch((err) => console.log(err))
        } else {
          dispatch(setShouldGoToNextStep(true))
        }
      })
      .catch((e) => console.error(e))
  }

  return (
    <Grid container>
      <Grid item lg={8} sx={{ width: 1 }}>
        <WizardStep
          progressBar={{ max: stepCount - 1, current: activeStep + 1 }}
          stepInfo={{
            currentStepLabel: stegGuide.nuvarandeSteg,
            nextStepLabel: stegGuide.nastaSteg,
          }}
          nextButton={{ onClick: handleNext }}
          prevButton={{}}
        >
          <Befattningar
            content={befattning}
            tooltipContent={tooltipBefattning}
            formikContext={formikContext}
          />

          <Gutter xs={32} />

          <VD />

          <Gutter xs={32} />

          <DateInput
            formikFieldName={AnstallningFormTypes.Anstallningsdatum}
            formikContext={formikContext}
            label={anstallningsdatum.textFaltTitel}
            maxDate={yesterday}
            title={replaceEpiVariables(anstallningsdatum.titel, {
              foretag: foretagNamn,
            })}
          />

          <Gutter xs={32} />

          <ArbetstimmarPerVecka />

          <Gutter xs={32} />

          <Anstallningsgrad />

          <Gutter xs={32} />

          <Bilagor
            content={{
              ...laddaUppBilaga,
              heading: bifogaArbetsgivarintyg.heading,
              uploadButtonText: bifogaArbetsgivarintyg.laddaUppKnapp,
            }}
            formikFieldName={AnstallningFormTypes.AnstallningBilagor}
            files={values.anstallningBilagor}
            setFieldValue={setFieldValue}
            errorMessage={generateErrorMessage({
              touched: touched?.anstallningBilagor !== undefined,
              errorMsg: errors?.anstallningBilagor as string,
            })}
          />
        </WizardStep>
      </Grid>
    </Grid>
  )
}

const AnstallningTBFormik = withFormik<
  IAnstallningTbFormProps,
  IAnstallningTBFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ ansokan }) =>
    mapAnsokanToAnstallningTbFormValues(ansokan),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content }: { content: IAnstallningTbContent }) =>
    anstallningTBValidationSchema(content),
  displayName: 'AnstallningTbForm',
})(AnstallningTBForm)

export default AnstallningTBFormik
