import React from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITjanstepersonAnsokanContent } from '@local/Types'
import EmptyResult from '@local/Components/EmptyResult'

const InskickadAnsokan = () => {
  const {
    redanInskickad: { textInnehall, link },
  } = usePickEpiContent<ITjanstepersonAnsokanContent>()

  return (
    <EmptyResult
      heading={textInnehall.titel}
      description={textInnehall.body}
      link={link.url}
      linkText={link.heading}
    />
  )
}

export default InskickadAnsokan
