import React from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useFormikContext } from 'formik'
import { IOnboardingFormValues } from '@local/Views/OnboardingWrapper/Onboarding/OnboardingForm/OnboardingForm.schema'
import { ITjanstepersonAnsokanContent } from '@local/Types'
import { isNil } from 'ramda'
import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage'

const Villkor = (): JSX.Element => {
  const {
    onboarding: { accepteraVillkor },
  } = usePickEpiContent<ITjanstepersonAnsokanContent>()

  const { handleChange, handleBlur, errors, touched, values } =
    useFormikContext<IOnboardingFormValues>()

  const isError = touched.accepteratVillkor && !isNil(errors.accepteratVillkor)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <FormControlLabel
        control={
          <Checkbox
            checked={values.accepteratVillkor}
            color="primary"
            onChange={handleChange}
            onBlur={handleBlur}
            name="accepteratVillkor"
          />
        }
        label={accepteraVillkor.titel}
      />
      {isError && (
        <FormErrorMessage>{errors.accepteratVillkor}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default Villkor
