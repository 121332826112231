import React from 'react'
import { FormikContextType } from 'formik'
import HTMLMapper from '@local/Components/HTMLMapper'
import { useGetAnsokanState } from '@local/Services/API/hamtaAnsokanApi'
import { IEditorialBlock } from '@local/Types'
import {
  replaceEpiVariables,
  setFieldValue as customSetFieldValue,
} from '@local/Utils/Helpers'
import { IBefattningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types/content'
import Autocomplete from '@local/Components/Befattningar/Autocomplete'
import { ISelectOptionFormikWrapper } from '@local/Types/form.types'
import Tooltip from '@local/Components/Tooltip'

interface IBefattning {
  befattning: ISelectOptionFormikWrapper
}

interface BefattningarProps {
  content: IBefattningContent
  tooltipContent: IEditorialBlock
  formikContext: FormikContextType<IBefattning>
}

export const Befattningar = ({
  content,
  tooltipContent,
  formikContext,
}: BefattningarProps) => {
  const { values, setFieldValue, setFieldTouched } = formikContext

  const {
    data: {
      foretag: { namn: foretagNamn },
    },
  } = useGetAnsokanState()

  return (
    <>
      <Tooltip
        heading={replaceEpiVariables(content.titel, {
          foretag: foretagNamn,
        })}
        tooltipContent={<HTMLMapper body={tooltipContent.mainBody} />}
      />

      <Autocomplete
        formikName="befattning"
        label={content.textFaltTitel}
        placeholder={content.textFaltTitel}
        value={values.befattning}
        formikContext={formikContext}
        onChange={customSetFieldValue(
          setFieldValue,
          'befattning',
          setFieldTouched
        )}
      />
    </>
  )
}
