import React from 'react'
import EmptyIllustration from '@local/Components/EmptyResult/EmptyIllustration'
import { styled } from '@mui/material/styles'
import { IEmptyResult } from '@local/Components/EmptyResult/Types'
import { Button, Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'

const StyledEmptyResult = styled('aside')(() => ({
  textAlign: 'center',

  '& .empty-illustration': {
    maxWidth: 385,
    padding: '0px 40px',
  },
}))

const EmptyResult = ({
  heading,
  description,
  link,
  linkText,
}: IEmptyResult): JSX.Element => (
  <StyledEmptyResult>
    <EmptyIllustration className="empty-illustration" />

    <Gutter xs={24} />

    <Typography variant="h2">{heading}</Typography>

    <Gutter xs={24} />

    <Typography variant="body1">{description}</Typography>

    <Gutter xs={32} />

    {link && (
      <Button variant="contained" href={link}>
        {linkText}
      </Button>
    )}
  </StyledEmptyResult>
)

export default EmptyResult
