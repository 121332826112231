import React, { useCallback } from 'react'
import { mapAnsokanToArbetsplatsFormValues } from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Helpers'
import {
  IArbetsplatsContent,
  IArbetsplatsFormProps,
} from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Types/content'
import { useWizard, WizardStep } from '@trr/wizard-library'
import {
  FormikTouched,
  setNestedObjectValues,
  useFormikContext,
  withFormik,
} from 'formik'
import { isEmpty } from 'ramda'
import { RadioChoice } from '@local/Types/form.types'
import Gutter from '@local/Components/Gutter'
import {
  usePickEpiContent,
  useFormikWatch,
  useWizardNextStep,
} from '@local/Utils/Hooks'
import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import { useGetAnsokanState } from '@local/Services/API/hamtaAnsokanApi'
import { useHistory } from 'react-router-dom'
import { QueryParams } from '@local/Router/routes'
import { arbetsplatsMiddleware } from '@local/Views/SkapaAnsokan/Components/Arbetsplats/arbetsplatsMiddleware'
import { IArbetsplatsFormValues } from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Types'
import { SkapaAnsokanSharedContent } from '@local/Types'
import { setShouldGoToNextStep } from '@local/Utils/Hooks/formikWatchSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import { arbetsplatsValidationSchema } from '@local/Views/SkapaAnsokan/Components/Arbetsplats/ArbetsplatsForm.schema'
import NuvarandeArbetsplats from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Components/NuvarandeArbetsplats'
import Agarandel from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Components/Agarandel'
import Organisationsnummer from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Components/Organisationsnummer'
import { Grid } from '@mui/material'
import { useGetArbetsgivareFromState } from '@local/Utils/Hooks/SkapaAnsokan'

const ArbetsplatsForm = () => {
  const dispatch = useAppDispatch()
  const { stepCount, activeStep } = useWizard()
  const history = useHistory()
  const [patchAnsokan] = usePatchAnsokanMutation({
    fixedCacheKey: 'patchAnsokan',
  })
  const getArbetsgivareFromState = useGetArbetsgivareFromState()

  const {
    data: {
      foretag: { namn: foretagNamn },
    },
  } = useGetAnsokanState()

  const patchAnsokanCallback = useCallback(
    async (values: Partial<IArbetsplatsFormValues>) => {
      await patchAnsokan({ values })
    },
    [patchAnsokan]
  )

  useFormikWatch(arbetsplatsMiddleware, patchAnsokanCallback)
  useWizardNextStep()

  const { values, validateForm, setTouched } =
    useFormikContext<IArbetsplatsFormValues>()

  const {
    arbetsplats: { stegGuide },
    tjanstepersonansokanLinks,
  } = usePickEpiContent<SkapaAnsokanSharedContent>()

  const handleNext = (): void => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          setTouched(
            setNestedObjectValues<FormikTouched<IArbetsplatsFormValues>>(
              validationErrors,
              true
            )
          ).catch((err) => console.log(err))
        } else {
          getArbetsgivareFromState()
            .unwrap()
            .then(({ results }) => {
              if (!isEmpty(results)) {
                values.nuvarandeArbetsplats === RadioChoice.Yes
                  ? dispatch(setShouldGoToNextStep(true))
                  : patchAnsokan({
                      values: {
                        foretagNamn: results[0].name,
                        foretagOrganisationsnummer:
                          values.foretagorganisationsnummer.replace('-', ''),
                        foretagId: results[0].id,
                      },
                    })
                      .unwrap()
                      .then(() => dispatch(setShouldGoToNextStep(true)))
                      .catch((err) => console.log(err))
              } else {
                history.push(
                  `${tjanstepersonansokanLinks.ogiltigtOrgnummerUrl}?${QueryParams.OgiltigtOrgnummer}=${values.foretagorganisationsnummer}`
                )
              }
            })
            .catch((err) => console.log(err))
        }
      })
      .catch((e) => console.log(e))
  }
  return (
    <Grid container>
      <Grid item lg={8} sx={{ width: 1 }}>
        <WizardStep
          progressBar={{ max: stepCount - 1, current: activeStep + 1 }}
          stepInfo={{
            currentStepLabel: stegGuide.nuvarandeSteg,
            nextStepLabel: stegGuide.nastaSteg,
          }}
          nextButton={{ onClick: handleNext }}
          prevButton={{}}
        >
          {foretagNamn && (
            <>
              <NuvarandeArbetsplats />

              <Gutter xs={32} />
            </>
          )}

          {values.nuvarandeArbetsplats === RadioChoice.No && (
            <>
              <Organisationsnummer />
              <Gutter xs={32} />
            </>
          )}

          <Agarandel />
        </WizardStep>
      </Grid>
    </Grid>
  )
}

const ArbetsplatsFormik = withFormik<
  IArbetsplatsFormProps,
  IArbetsplatsFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ ansokan }) => mapAnsokanToArbetsplatsFormValues(ansokan),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content }: { content: IArbetsplatsContent }) =>
    arbetsplatsValidationSchema(content),
  displayName: 'ArbetsplatsForm',
})(ArbetsplatsForm)

export default ArbetsplatsFormik
