import React from 'react'
import HTMLMapper from '@local/Components/HTMLMapper'
import { trrDateStrict } from '@local/Utils/Helpers/formatDate'
import Tooltip from '@local/Components/Tooltip'
import Gutter from '@local/Components/Gutter'
import { TextField, Typography, useTheme } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { isNil } from 'ramda'
import { IDateInput } from '@local/Components/DateInput/Types'

const DateInput = ({
  formikContext,
  formikFieldName,
  tooltipContent,
  title,
  label,
  minDate,
  maxDate,
}: IDateInput) => {
  const theme = useTheme()
  const { errors, touched, values, setFieldValue, handleBlur } = formikContext

  const isError =
    touched?.[formikFieldName] && !isNil(errors?.[formikFieldName])

  const handleOnChange = (formikFieldName: string, value: Date) => {
    setFieldValue(formikFieldName, value).catch((err) => console.log(err))
  }

  return (
    <>
      {tooltipContent ? (
        <Tooltip
          heading={title}
          tooltipContent={<HTMLMapper body={tooltipContent.mainBody} />}
        />
      ) : (
        <>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Gutter xs={8} />
        </>
      )}

      <DatePicker
        onChange={(value) => handleOnChange(formikFieldName, value)}
        value={trrDateStrict(values[formikFieldName] as Date)}
        label={label}
        minDate={minDate ?? new Date(null)}
        maxDate={maxDate}
        renderInput={(params) => (
          <TextField
            {...params}
            error={isError}
            helperText={isError ? String(errors?.[formikFieldName]) : null}
            sx={{ width: '100%' }}
            name={formikFieldName}
            onBlur={handleBlur}
          />
        )}
        desktopModeMediaQuery={theme.breakpoints.up('lg')}
      />
    </>
  )
}

export default DateInput
