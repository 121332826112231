import { useGetAnsokanState } from '@local/Services/API/hamtaAnsokanApi'
import { octoberStart } from '@local/Utils/Helpers/Datetime.helpers'
import { trrDate } from '@local/Utils/Helpers/formatDate'
import { isBefore } from 'date-fns'

const useGetSistaAnstallningsdatumMinDate = () => {
  const {
    data: {
      anstallningsuppgifter: { anstallningsdatum },
    },
  } = useGetAnsokanState()

  const anstallningsdatumDate = trrDate(anstallningsdatum)

  const minDate = isBefore(anstallningsdatumDate, octoberStart)
    ? octoberStart
    : anstallningsdatumDate

  return minDate
}

export default useGetSistaAnstallningsdatumMinDate
