import { IArbetslivserfarenhetTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Types'
import { IArbetslivserfarenhetTIAContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Types/content'
import * as Yup from 'yup'

export const arbetslivserfarenhetTIAValidationSchema = (
  content: IArbetslivserfarenhetTIAContent
): Yup.AnySchema<IArbetslivserfarenhetTIAFormValues> =>
  Yup.object().shape({
    hasIntygatEtableringsvillkor: Yup.mixed().required(
      content.etableringsvillkor.varning
    ),
    arbetstimmarPerVecka: Yup.number()
      .typeError(content.arbetstimmar.varning)
      .required(content.arbetstimmar.varning),
  })

export const initialArbetslivserfarenhetTIAValues: IArbetslivserfarenhetTIAFormValues =
  {
    hasIntygatEtableringsvillkor: null,
    arbetstimmarPerVecka: null,
  }
