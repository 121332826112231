import React from 'react'
import { usePickEpiContent, useGtmWizardTracker } from '@local/Utils/Hooks'
import { SkapaAnsokanSharedContent } from '@local/Types'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import ArbetsplatsForm from '@local/Views/SkapaAnsokan/Components/Arbetsplats/ArbetsplatsForm'
import Spinner from '@local/Components/Spinner'
import { useIsTiaAnsokan } from '@local/Utils/Hooks/SkapaAnsokan'

const Arbetsplats = () => {
  const { arbetsplats } = usePickEpiContent<SkapaAnsokanSharedContent>()
  const isTia = useIsTiaAnsokan()

  useGtmWizardTracker(isTia ? 'TIA-Ansökan' : 'TB-Ansökan')

  const { data, isLoading } = useGetAnsokanQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  if (isLoading) {
    return <Spinner centered />
  }

  return <ArbetsplatsForm content={arbetsplats} ansokan={data} />
}

export default Arbetsplats
