import React from 'react'
import Gutter from '@local/Components/Gutter'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { QueryParams } from '@local/Router/routes'
import EpiImage from '@local/Components/EpiImage/EpiImage'
import { IOgiltigtOrgnummerContent } from '@local/Views/OgiltigtOrgnummer/Types/content.types'
import { Grid, Link, Typography } from '@mui/material'

const OgiltigtOrgnummer = () => {
  const {
    textinnehall: { titel, body, andraAlternativ },
    kontaktaOss,
    borjaOmFranBorjan,
    visaAndraAktorer,
    bild,
  } = usePickEpiContent<IOgiltigtOrgnummerContent>()

  const orgnummer = new URLSearchParams(window.location.search).get(
    QueryParams.OgiltigtOrgnummer
  )

  return (
    <Grid container>
      <Grid item lg={8}>
        <EpiImage url={bild.url} />

        <Gutter xs={40} />

        <Typography variant="h3">
          {replaceEpiVariables(titel, { orgnummer })}
        </Typography>

        <Gutter xs={24} />

        <Typography variant="body1">{body}</Typography>

        <Gutter xs={32} />

        <Link target="_blank" href={kontaktaOss.url}>
          {kontaktaOss.heading}
        </Link>

        <Gutter xs={64} />

        <Typography variant="subtitle1">{andraAlternativ}</Typography>

        <Gutter xs={16} />

        <Link href={borjaOmFranBorjan.url}>{borjaOmFranBorjan.heading}</Link>

        <Gutter xs={16} />

        <Link target="_blank" href={visaAndraAktorer.url}>
          {visaAndraAktorer.heading}
        </Link>
      </Grid>
    </Grid>
  )
}

export default OgiltigtOrgnummer
