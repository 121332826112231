import React from 'react'
import getConfig from '@local/Utils/getConfig'
import {
  createImageAltTextFromFileName,
  getImageQuery,
} from '@local/Utils/Helpers'
import { styled } from '@mui/material/styles'

const StyledEpiImage = styled(
  ({ src, alt, ...props }: { src: string; alt: string }) => (
    <img src={src} alt={alt} {...props} />
  )
)(() => ({
  maxWidth: '320px',
}))

const EpiImage = ({ url }: { url: string }): JSX.Element => {
  const { MEDIA_URL } = getConfig()

  return (
    <StyledEpiImage
      src={getImageQuery(MEDIA_URL + url, 500)}
      alt={createImageAltTextFromFileName(url)}
    />
  )
}

export default EpiImage
