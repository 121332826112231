import React from 'react'
import {
  granskaAnsokanValidationSchema,
  IGranskaAnsokanFormValues,
  initialGranskaAnsokanValues,
} from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan/GranskaAnsokanForm.schema'
import {
  IGranskaAnsokanContent,
  IGranskaAnsokanFormProps,
} from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan/Types/content'
import { useWizard, WizardStep } from '@trr/wizard-library'
import { FormikProps, withFormik } from 'formik'
import Gutter from '@local/Components/Gutter'
import {
  useToggle,
  usePickEpiContent,
  useWizardNextStep,
} from '@local/Utils/Hooks'
import { SkapaAnsokanSharedContent } from '@local/Types'
import GranskaAnsokanFormModal from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan/Components/GranskaAnsokanFormModal'
import { Grid, Typography } from '@mui/material'
import Accordion from '@local/Components/Accordion'
import { SammanfattningSektion } from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan/Components/SammanfattningSektion'
import { Divider } from '@local/Components/Divider/Divider'

const GranskaAnsokanForm = ({
  sammanfattningSektioner,
}: IGranskaAnsokanFormProps & FormikProps<IGranskaAnsokanFormValues>) => {
  const { stepCount, activeStep } = useWizard()
  const { isToggled: isModalOpen, handleToggle } = useToggle()

  useWizardNextStep()

  const {
    granskaAnsokan: { stegGuide, textinnehall },
  } = usePickEpiContent<SkapaAnsokanSharedContent>()

  return (
    <Grid container>
      <Grid item lg={8} sx={{ width: 1 }}>
        <WizardStep
          progressBar={{ max: stepCount - 1, current: activeStep + 1 }}
          stepInfo={{
            currentStepLabel: stegGuide.nuvarandeSteg,
            nextStepLabel: stegGuide.nastaSteg,
          }}
          nextButton={{
            onClick: handleToggle,
            text: stegGuide.nastaKnapp,
          }}
          prevButton={{}}
        >
          <Gutter xs={8} />

          <Typography variant="body1">{textinnehall.titel}</Typography>

          <Gutter xs={32} />

          <Accordion
            heading={textinnehall.sektionTitel}
            disableGutters
            ariaControls="granska-ansokan-accordion-content"
            ariaControlsId="granska-ansokan-accordion-header"
          >
            {sammanfattningSektioner.map((sektion, index: number) => (
              <div key={sektion.title}>
                <SammanfattningSektion {...sektion} />
                {index !== sammanfattningSektioner.length - 1 && (
                  <>
                    <Divider />

                    <Gutter xs={24} />
                  </>
                )}
              </div>
            ))}
          </Accordion>

          <GranskaAnsokanFormModal
            isModalOpen={isModalOpen}
            toggleModal={handleToggle}
          />
        </WizardStep>
      </Grid>
    </Grid>
  )
}

const GranskaAnsokanFormik = withFormik<
  IGranskaAnsokanFormProps,
  IGranskaAnsokanFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: () => initialGranskaAnsokanValues,
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content }: { content: IGranskaAnsokanContent }) =>
    granskaAnsokanValidationSchema(content),
  displayName: 'GranskaAnsokanForm',
})(GranskaAnsokanForm)

export default GranskaAnsokanFormik
