import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  useGetAnsokanDraftQuery,
  useKanAnsokaQuery,
} from '@local/Services/API/hamtaAnsokanApi'
import { useDeleteAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import InskickadAnsokan from '@local/Views/OnboardingWrapper/InskickadAnsokan'
import PaborjadAnsokan from '@local/Views/OnboardingWrapper/PaborjadAnsokan'
import Onboarding from '@local/Views/OnboardingWrapper/Onboarding'
import { PaborjadAnsokanVal } from '@local/Views/OnboardingWrapper/PaborjadAnsokan/Types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITjanstepersonAnsokanContent } from '@local/Types'
import Spinner from '@local/Components/Spinner'
import { useAuthentication } from '@trr/app-shell-data'

const OnboardingWrapper = () => {
  const { sub } = useAuthentication()
  const history = useHistory()
  const [continueAnsokan, setContinueAnsokan] = useState(false)
  const [ansokanVal, setAnsokanVal] = useState(PaborjadAnsokanVal.Fortsatt)

  const { data: ansokanDraft, isFetching: isFetchingDraft } =
    useGetAnsokanDraftQuery(sub)
  const { data: kanAnsoka, isLoading: isLoadingKanAnsoka } = useKanAnsokaQuery()

  const [deleteAnsokan] = useDeleteAnsokanMutation()
  const {
    onboarding: {
      avbrytDirigering: { avbrytLink },
    },
  } = usePickEpiContent<ITjanstepersonAnsokanContent>()

  const onContinueAnsokan = (): Promise<void> =>
    new Promise<void>((resolve) => {
      switch (ansokanVal) {
        case PaborjadAnsokanVal.Fortsatt:
          setContinueAnsokan(true)
          resolve()
          break
        case PaborjadAnsokanVal.Radera:
          deleteAnsokan(ansokanDraft.id)
            .unwrap()
            .then(() => {
              history.push(avbrytLink)
              resolve()
            })
            .catch((e) => console.log(e))
          break
      }
    })

  if (isFetchingDraft || isLoadingKanAnsoka) {
    return <Spinner centered />
  }

  if (!kanAnsoka) {
    return <InskickadAnsokan />
  }

  if (ansokanDraft !== null && !continueAnsokan && kanAnsoka) {
    return (
      <PaborjadAnsokan
        ansokanValValue={ansokanVal}
        onContinue={onContinueAnsokan}
        setAnsokanVal={setAnsokanVal}
      />
    )
  }

  return <Onboarding />
}

export default OnboardingWrapper
