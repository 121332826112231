import * as Yup from 'yup'
import { RadioChoice } from '@local/Types/form.types'
import { countDecimals } from '@local/Utils/Helpers/Forms/form.helpers'
import { ILonOchErsattningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Types'
import { ILonOchErsattningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Types/content'

export const lonOchErsattningValidationSchema = (
  content: ILonOchErsattningContent
): Yup.AnySchema<ILonOchErsattningFormValues> =>
  Yup.object().shape({
    manadslon: Yup.number()
      .required(content.manadslon.varning)
      .typeError(content.manadslon.varning)
      .integer(content.manadslon.varningDecimaler)
      .positive(content.manadslon.varningNegativt)
      .max(999999, content.manadslon.maxAmountExceeded),
    provision: Yup.mixed()
      .oneOf(Object.values(RadioChoice))
      .required(content.provision.varning),
    provisionBelopp: Yup.number()
      .nullable()
      .when('provision', {
        is: RadioChoice.Yes,
        then: Yup.number()
          .required(content.provisionBelopp.varning)
          .positive(content.provisionBelopp.varningNegativt)
          .test(
            'len',
            content.provisionBelopp.varningDecimaler,
            (value: number) => countDecimals(value) <= 2
          )
          .max(999999, content.provisionBelopp.maxAmountExceeded)
          .typeError(content.provisionBelopp.varning),
      }),
    jour: Yup.mixed()
      .oneOf(Object.values(RadioChoice))
      .required(content.jour.varning),
    jourBelopp: Yup.number()
      .nullable()
      .when('jour', {
        is: RadioChoice.Yes,
        then: Yup.number()
          .required(content.jourBelopp.varning)
          .positive(content.jourBelopp.varningNegativt)
          .test(
            'len',
            content.jourBelopp.varningDecimaler,
            (value: number) => countDecimals(value) <= 2
          )
          .max(999999, content.jourBelopp.maxAmountExceeded)
          .typeError(content.jourBelopp.varning),
      }),
    sjukersattningsgradChoice: Yup.mixed()
      .oneOf(Object.values(RadioChoice))
      .required(content.sjukersattningsgradChoice.varning),
    sjukersattningsgrad: Yup.number()
      .nullable()
      .when('sjukersattningsgradChoice', {
        is: RadioChoice.Yes,
        then: Yup.number()
          .required(content.sjukersattningsgrad.varning)
          .positive(content.sjukersattningsgrad.varningNegativt)
          .max(100, content.sjukersattningsgrad.varningHogtVarde)
          .integer(content.sjukersattningsgrad.varningDecimaler)
          .typeError(content.sjukersattningsgrad.varning),
      }),
  })

export const initialLonOchErsattningValues: ILonOchErsattningFormValues = {
  manadslon: null,
  provision: null,
  provisionBelopp: null,
  jour: null,
  jourBelopp: null,
  sjukersattningsgradChoice: null,
  sjukersattningsgrad: null,
}
