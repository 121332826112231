import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  useGetAnsokanDraftState,
  useKanAnsokaQuery,
} from '@local/Services/API/hamtaAnsokanApi'
import { SkapaAnsokanSharedContent } from '@local/Types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useAuthentication } from '@trr/app-shell-data'

const WizardGuard: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const { sub } = useAuthentication()
  const { isUninitialized } = useGetAnsokanDraftState(sub)
  const { data: kanAnsoka } = useKanAnsokaQuery()
  const { tjanstepersonansokanLinks } =
    usePickEpiContent<SkapaAnsokanSharedContent>()

  if (isUninitialized || kanAnsoka === false) {
    return <Redirect to={tjanstepersonansokanLinks.ansokanUrl} />
  }

  return <>{children}</>
}

export default WizardGuard
