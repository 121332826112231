import React, { useEffect, useState } from 'react'
import { Wizard } from '@trr/wizard-library'
import WizardGuard from '@local/Components/WizardGuard/WizardGuard'
import Arbetsplats from '@local/Views/SkapaAnsokan/Components/Arbetsplats'
import AnstallningTIA from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA'
import GranskaAnsokanTIA from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokanTIA'
import SlutfordAnsokan from '@local/Views/SkapaAnsokan/Components/SlutfordAnsokan'
import ArbetslivserfarenhetTIA from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA'
import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import Toaster from '@local/Components/Toaster'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'

const TjanstemanIAnstallning = (): JSX.Element => {
  const [toasterOpen, setToasterOpen] = useState(false)
  const { patchGickFel } = usePickEpiContent<ITjanstemanIAnstallningContent>()
  const [, { isError: isErrorPatchAnsokan }] = usePatchAnsokanMutation({
    fixedCacheKey: 'patchAnsokan',
  })
  useEffect(() => {
    if (isErrorPatchAnsokan) {
      setToasterOpen(true)
    } else {
      setToasterOpen(false)
    }
  }, [isErrorPatchAnsokan])

  return (
    <>
      <Toaster
        message={patchGickFel.heading}
        open={toasterOpen}
        setOpen={setToasterOpen}
      />
      <Wizard>
        <WizardGuard>
          <Arbetsplats />
        </WizardGuard>

        <WizardGuard>
          <AnstallningTIA />
        </WizardGuard>

        <WizardGuard>
          <ArbetslivserfarenhetTIA />
        </WizardGuard>

        <WizardGuard>
          <GranskaAnsokanTIA />
        </WizardGuard>

        <SlutfordAnsokan />
      </Wizard>
    </>
  )
}

export default TjanstemanIAnstallning
