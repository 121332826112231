import * as Yup from 'yup'
import { IAnstallningTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types'
import { IAnstallningTIAContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types/content'
import { RadioChoice } from '@local/Types/form.types'
import { isAfter, isBefore, subDays } from 'date-fns'
import { getDate } from '@local/Utils/Helpers/formatDate'
import { startDate, today } from '@local/Utils/Helpers/Datetime.helpers'

export const anstallningTIAValidationSchema = (
  content: IAnstallningTIAContent
): Yup.AnySchema<IAnstallningTIAFormValues> =>
  Yup.object().shape({
    befattning: Yup.mixed().nullable().required(content.befattning.varning),
    isVdEllerUndantagsgrupp: Yup.mixed()
      .oneOf(Object.values(RadioChoice))
      .required(content.vd.varning),
    anstallningsdatum: Yup.date()
      .typeError(content.anstallningsdatum.varning)
      .required(content.anstallningsdatum.varning)
      .test(
        'validateMinDate',
        content.anstallningsdatum.varningMinDate,
        (date) => isAfter(date, subDays(startDate, 1))
      )
      .test(
        'validateMaxDate',
        content.anstallningsdatum.varningMaxDate,
        (date) => isBefore(new Date(getDate(date)), new Date(getDate(today)))
      ),
    manadslon: Yup.number()
      .required(content.manadslon.varning)
      .typeError(content.manadslon.varning)
      .integer(content.manadslon.varningDecimaler)
      .positive(content.manadslon.varningNegativt)
      .max(999999, content.manadslon.maxAmountExceeded),
    anstallningBilagor: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          name: Yup.string().nullable(),
        })
      )
      .min(2, content.bifogaArbetsgivarintyg.varning),
  })

export const initialAnstallningTIAValues: IAnstallningTIAFormValues = {
  befattning: null,
  isVdEllerUndantagsgrupp: undefined,
  anstallningsdatum: null,
  manadslon: null,
  anstallningBilagor: [],
}
