import React from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import { AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IAccordion } from '@local/Components/Accordion/Types'

const StyledAccordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={0} {...props} />
))(({ theme }) => ({
  borderTop: 0,
  borderRadius: 0,
  border: `1px solid ${theme.palette.neutral?.divider}`,

  '& .MuiAccordionSummary-root': {
    padding: `0 ${theme.spacing(3)}`,
  },
  '& .MuiAccordionSummary-content': {
    margin: `${theme.spacing(3)} 0`,
  },
  '& .MuiAccordionDetails-root': {
    padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  '&:before': {
    display: 'none',
  },
  '&:last-of-type': {
    borderRadius: 0,
  },
}))

const Accordion: React.FunctionComponent<
  React.PropsWithChildren<IAccordion>
> = ({
  heading,
  defaultExpanded = false,
  ariaControls,
  ariaControlsId,
  children,
  ...props
}): JSX.Element => (
  <StyledAccordion defaultExpanded={defaultExpanded} {...props}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={ariaControls}
      id={ariaControlsId}
    >
      {typeof heading === 'string' ? (
        <Typography variant="h5">{heading}</Typography>
      ) : (
        heading
      )}
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </StyledAccordion>
)

export default Accordion
