import React, { useCallback } from 'react'
import {
  FormikTouched,
  setNestedObjectValues,
  useFormikContext,
  withFormik,
} from 'formik'
import { useAppDispatch } from '@local/Store/configureStore'
import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import { useWizard, WizardStep } from '@trr/wizard-library'
import { isEmpty } from 'ramda'
import { setShouldGoToNextStep } from '@local/Utils/Hooks/formikWatchSlice'
import {
  useFormikWatch,
  usePickEpiContent,
  useWizardNextStep,
} from '@local/Utils/Hooks'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { IArbetslivserfarenhetTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Types'
import { arbetslivserfarenhetTIAMiddleware } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/arbetslivserfarenhetTIAMiddleware'
import Etableringsvillkor from '@local/Views/SkapaAnsokan/Components/Etableringsvillkor'
import {
  IArbetslivserfarenhetTIAContent,
  IArbetslivserfarenhetTIAFormProps,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Types/content'
import { mapAnsokanToArbetslivserfarenhetTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Helpers'
import { arbetslivserfarenhetTIAValidationSchema } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/ArbetslivserfarenhetTIAForm.schema'
import Gutter from '@local/Components/Gutter'
import Arbetstimmar from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Components/Arbetstimmar'
import { Grid } from '@mui/material'

const ArbetslivserfarenhetTIAForm = () => {
  const dispatch = useAppDispatch()
  const [patchAnsokan] = usePatchAnsokanMutation({
    fixedCacheKey: 'patchAnsokan',
  })
  const { stepCount, activeStep } = useWizard()

  const { setTouched, validateForm } =
    useFormikContext<IArbetslivserfarenhetTIAFormValues>()

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          setTouched(
            setNestedObjectValues<
              FormikTouched<IArbetslivserfarenhetTIAFormValues>
            >(validationErrors, true)
          ).catch((err) => console.log(err))
        } else {
          dispatch(setShouldGoToNextStep(true))
        }
      })
      .catch((e) => console.error(e))
  }

  const {
    arbetslivserfarenhet: { stegGuide },
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  const patchAnsokanCallback = useCallback(
    async (values: Partial<IArbetslivserfarenhetTIAFormValues>) => {
      await patchAnsokan({ values })
    },
    [patchAnsokan]
  )

  useFormikWatch(arbetslivserfarenhetTIAMiddleware, patchAnsokanCallback)
  useWizardNextStep()
  return (
    <Grid container>
      <Grid item lg={8} sx={{ width: 1 }}>
        <WizardStep
          progressBar={{ max: stepCount - 1, current: activeStep + 1 }}
          stepInfo={{
            currentStepLabel: stegGuide.nuvarandeSteg,
            nextStepLabel: stegGuide.nastaSteg,
          }}
          nextButton={{ onClick: () => handleNext() }}
          prevButton={{}}
        >
          <Arbetstimmar />

          <Gutter xs={32} />

          <Etableringsvillkor />
        </WizardStep>
      </Grid>
    </Grid>
  )
}

const ArbetslivserfarenhetTIAFormik = withFormik<
  IArbetslivserfarenhetTIAFormProps,
  IArbetslivserfarenhetTIAFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ ansokan }) =>
    mapAnsokanToArbetslivserfarenhetTIAFormValues(ansokan),
  displayName: 'ArbetslivserfarenhetTIAForm',
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({
    content,
  }: {
    content: IArbetslivserfarenhetTIAContent
  }) => arbetslivserfarenhetTIAValidationSchema(content),
})(ArbetslivserfarenhetTIAForm)

export default ArbetslivserfarenhetTIAFormik
