import { RadioChoice } from '@local/Types/form.types'
import { IOnboardingContent } from '@local/Views/OnboardingWrapper/Onboarding/Types/Onboarding.types'
import * as Yup from 'yup'

export interface IOnboardingFormValues {
  iAnstallning: RadioChoice
  uppsagdTyp: RadioChoice
  accepteratVillkor: boolean
}

export const onboardingValidationSchema = (
  content: IOnboardingContent
): Yup.AnySchema<IOnboardingFormValues> =>
  Yup.object().shape({
    iAnstallning: Yup.mixed()
      .oneOf(Object.values(RadioChoice))
      .required(content.anstallning.varning),
    uppsagdTyp: Yup.mixed().when('iAnstallning', {
      is: RadioChoice.No,
      then: Yup.mixed()
        .oneOf(Object.values(RadioChoice))
        .required(content.anstallning.varning),
    }),
    accepteratVillkor: Yup.boolean().oneOf(
      [true],
      content.accepteraVillkor.varning
    ),
  })

export const initialOnboardingValues: IOnboardingFormValues = {
  iAnstallning: null,
  uppsagdTyp: null,
  accepteratVillkor: false,
}
